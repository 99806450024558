<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>运营资质申请</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="95px" class="login-form">
            <div class="list block">
                <div class="flex">
                    <div>申请人：</div><div>{{ruleForm.person}}</div>
                </div>
                <div class="flex">
                    <div>申请日期：</div><div>{{ruleForm.time}}</div>
                </div>
                <div class="flex">
                    <div>申请企业：</div><div>{{ruleForm.title}}</div>
                </div>
                <div class="flex" style="margin:10px 0 20px 0;">
                    <div>可申请资源：</div>
                    <div class="select_list flex">
                        <div @click="select(idx)" :class="{'active':selectIndex==idx}" v-for="(item,idx) in selectList" :key="idx">
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <el-form-item prop="num" label="申请数量：">
                    <el-input type="number" style="width:200px;" v-model="ruleForm.num" placeholder="请输入数量"></el-input>
                </el-form-item>
                <el-form-item prop="desc" label="申请说明：">
                    <el-input type="textarea" :rows="3" v-model="ruleForm.desc" style="width:60%" placeholder="请输入内容"></el-input>
                </el-form-item>
                <div style="padding:20px 0 20px 95px;">
                    <el-button size="small" type="primary" style="width:80px;" @click="submitForm()">提交</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                rules: {
                    num: [
                        { required: true, message: '请输入申请数量', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输入申请说明', trigger: 'blur' }
                    ]
                },
                ruleForm:{
                    title:"xxxxx家具品牌公司",
                    person:"若风",
                    num:"",
                    stat:0,
                    time:"2020/1/12",
                    desc:"",
                },
                selectList:[
                    {id:0,name:"充值点数",},
                    {id:1,name:"需求发布次数",},
                    {id:2,name:"广播消息次数",},
                    {id:3,name:"查看次数",},
                ],
                selectIndex:0,
            }
        },
        created() {

        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.back();
                this.$message.success("提交成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 40px;
    }
    .list>div>div:first-child {
        width: 85px;
        text-align: right;
        color: #000;
        font-weight: 600;
        margin-right: 10px;
    }
    .select_list>div {
        padding:0 15px;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        border: 1px solid #606266;
        border-radius: 5px;
        margin-top: 3px;
        margin-right:15px;
    }
    .select_list>div.active {
        border: 1px solid rgb(64, 158, 255);
        color: rgb(64, 158, 255);
    }
    div>>> .el-form-item__label {
        font-weight: 600;
        color: #000;
    }
</style>